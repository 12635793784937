<template>
  <div class="registerContainer">
    <Navbar navTitle=" "></Navbar>
    <main style="max-width: 600px; margin: 20px auto 0; padding-bottom: 100px;">
      <div v-if="!haveBind" class="formBox">
        <div class="stepBox">
          <van-steps :active="stepIndex">
            <van-step>输入设备号</van-step>
            <van-step>检查DeeperChain钱包</van-step>
            <van-step>{{$t('user.step4')}}</van-step>
          </van-steps>
        </div>
        <div class="stepContent" v-if="codeCanUse">
          <Step1 :comps="$refs" ref="Step1" v-show="stepIndex == 0" @next="stepIndex = 1"></Step1>
          <Step2 :comps="$refs" ref="Step2" v-show="stepIndex == 1" @next="stepIndex = 2" @prev="stepIndex = 0"></Step2>
          <Step3 :comps="$refs" ref="Step3" v-show="stepIndex == 2" @next="stepIndex = 3" @prev="stepIndex = 1"></Step3>
        </div>
      </div>
      <div v-else class="formBoxStake">
        <h3 style="text-align: center">质押信息</h3>
        <div>
          <div style="font-weight: bold;color: #bbb">Deeper Chain:</div>
          <div style="margin-top: 10px">
            {{bindData.deeperChain}}
          </div>
        </div>
        <div style="margin-top: 30px;">
          <div style="font-weight: bold; color: #bbb">设备sn: </div>
          <div style="margin-top: 10px">
            {{bindData.sn}}
          </div>
        </div>
        <div style="margin-top: 30px;">
          <div style="font-weight: bold;color: #bbb">质押数量</div>
          <div style="text-align: center; font-size: 30px; color: #ff976a">
            2000 DPR
          </div>
        </div>
      </div>
    
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer'
import Step1 from './Step1.vue'
import Step2 from './Step2.vue'
import Step3 from './Step4.vue'
export default {
  components: {
    Footer,
    Step1,
    Step2,
    Step3
  },
  data() {
    return {
      stepIndex: 0,
      code: '',
      codeCanUse: false,
      haveBind: false,
      bindData: {}
    }
  },
  watch: {
    stepIndex(newVal) {
      if (this.$refs[`Step${newVal+1}`] && this.$refs[`Step${newVal+1}`].init) {
        this.$refs[`Step${newVal+1}`].init()
      }
    }
  },
  async mounted() {
    let code = this.$route.query.code;
    if (!code) {
      this.$router.replace("/login")
      return
    }
    this.code = code;
    this.checkCode()
  },
  methods: {
    checkCode() {
      return this.$fetch({
        url: '/dealer/checkStakingCode',
        params: {
          stakingCode: this.code
        }
      }).then(res => {
        console.log(res)
        if (res.success && !res.reason) {
          this.codeCanUse = true
        }
        if (res.reason && res.data && res.data.deeperChain) {
          this.haveBind = true;
          this.bindData = res.data;
          return
        }
      
      }).catch(e => {
        this.$dialog.alert({
          title: '质押码无效',
          message: '该质押码错误或者已经被绑定。',
        }).then(() => {
          // on close
        });
      })
    }
  }
  
}
</script>
<style>
html, body, #app {
  height: 100%
}
</style>
<style lang="less" scoped>

.registerContainer {
  min-height: 100%;
  background: #001938;
}
.stepBox {
  padding: 20px;
}
.formBox {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px 0;
  overflow: hidden;
  box-shadow: 0 0px 5px #333;
}
.formBoxStake {
  color: #eee;
  border: 1px solid #ddd;
  border-radius: 30px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.1);
}
.stepContent {
  padding-bottom: 30px;
}

</style>
