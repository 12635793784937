<template>
  <div class="step1container">
    <div style="text-align: center">
      设备绑定的Deeper Chain钱包：
      <div style="font-size: 12px;margin: 20px 0;">
        <span style="padding: 20px; border: 1px dashed #999;display: inline-block;min-width: 50%">
          {{deeperChain || '请按照如下教程绑定钱包'}}
        </span>
      </div>
    </div>
    <div v-if="!deeperChain" class="imgBox">
      <img @click="openImg" :src="createWalletImg" alt="">
    </div>
    <div style="display: flex; justify-content: space-between; padding: 0 30px;">
      <van-button type="info" @click="$emit('prev')">上一步</van-button>
      <van-button type="primary" :loading="submitLoading" :disabled="deeperChain == 'loading...' || !deeperChain" @click="onSubmit">提交信息</van-button>
    </div>
  </div>
</template>

<script>
import createWalletImg from '@/assets/createWallet.png'
export default {
  props: {
    comps: null
  },
  data() {
    return {
      sn: '',
      code: '',
      deeperChain: 'loading...',
      createWalletImg,
      submitLoading: false
    }
  },
  methods: {
    onSubmit() {
      this.submitLoading = true;
      this.$fetch({
        url: '/dealer/registerFromCode',
        method: 'POST',
        data: {
          stakingCode: this.code,
          sn: this.sn,
        }
      }).then(res => {
        console.log(res)
        if (res.success) {
          this.$emit('next')
        }
      }).finally(() => {
        this.submitLoading = false
      })
    },
    openImg() {
      window.open(createWalletImg)
    },
    async init() {
      const toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      await this.initData();
      await this.getDeeperChain();
      toast.clear()
    },
    initData() {
      this.sn = this.comps.Step1.finalSN;
      this.code = this.$route.query.code
    },
    getDeeperChain() {
      return this.$fetch({
        url: '/dealer/getDeeperChainBySN',
        params: {
          sn: this.sn,
          code: this.code
        }
      }).then(res => {
        if (res.data && res.data.length) {
          this.deeperChain = res.data[0].publicKey
        } else {
          this.deeperChain = ''
        }
        
        if (!this.deeperChain) {
          this.$Dialog.confirm({
            message: '您的设备尚未绑定DeeperChain钱包，请按照以下操作先在设备绑定钱包。'
          })
        }
      })
    },
    nextStep() {
      if (!this.sn) {
        return this.$Notify({
          type: 'danger',
          message: '请填写设备号'
        })
      }
      if (!this.sn.split('|')[1]) {
        return this.$Notify({
          type: 'danger',
          message: '请填写正确的设备号'
        })
      }
      this.$emit('next');
      

    }
  }
}
</script>

<style lang="less" scoped>
.step1container {
  .inputSNBox {
    text-align: center;
  }
  .imgBox {
    padding: 20px;
    img {
      width: 100%;
    }
  }
}
</style>